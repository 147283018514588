import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const jet_skies_tb_tours = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'JET SKIES TB TOURS | HB Privileged',
      title: 'JET SKIES TB TOURS',
      subtitle: 'TOUR OVERVIEW',
      text: "No, these are not the ones you find on the beach. This is a real experience full of adrenaline and excitement while you ́re on a last generation Jet Ski. Whether it ́s your first time here or an expert, you will enjoy the amazing views of our Bay while you jump over the waves at full speed. We go across all of Puerto Vallarta from the Marina to Boca de Tomatlán, passing by our famous Malecon with a quick stop for snorkeling at Los Arcos. The snorkeling gear is included! The total time for this excursion is 2 and a half ours of pure fun and adventure. Definitely something that you have to do if you want to release some stress.",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'JET SKIES TB TOURS | HB Privileged',
      title: 'JET SKIES TB TOURS',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'No, estas no son las motos de agua que encuentras en la playa. Esta es una verdadera experiencia llena de adrenalina y emociones mientras estás a bordo de un Jet Ski de última generación. Ya sea que seas primerizo o todo un experto, disfrutarás de los paisajes asombrosos de nuestra bahía, mientras brincas las olas a toda velocidad. Cruzamos todo Puerto Vallarta desde la marina hasta Boca de Tomatlán, pasando por nuestro famoso malecón y con una parada para snorkelear en Los Arcos. El equipo de snorkel está incluído! La duración total de esta excursión es de 2 horas y media de pura diversión y aventura. Definitivamente algo que hay que hacer para si se desea liberar un poco de estrés.',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/jet-skies-tb-tours1.jpg`,
    `${BASE_URL}/tours/jet-skies-tb-tours2.jpg`,
    `${BASE_URL}/tours/jet-skies-tb-tours3.jpg`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/jet-skies-tb-tours.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default jet_skies_tb_tours;